import React from 'react';
import * as styles from './HeroSection.module.css';

interface HeroSectionProps {
  heading: string;
  subHeading?: string;
  description: string;
  imagePath: string;
}

const HeroSection: React.FC<HeroSectionProps> = ({
  heading,
  subHeading,
  description,
  imagePath,
}) => {
  const renderSubHeading = (subHeading?: string) => {
    if (!subHeading) return null;

    // 英語の大文字を赤色に変更する処理
    const styledSubHeading = subHeading.split('').map((char, index) => {
      // 英語の大文字かどうかをチェック
      if (/[A-Z]/.test(char)) {
        // 大文字の場合は、span タグで囲み、赤色のスタイルを適用
        return (
          <span key={index} style={{ color: 'red' }}>
            {char}
          </span>
        );
      }
      // その他の文字はそのまま返す
      return char;
    });
    return <h2 className={styles.subHeading}>{styledSubHeading}</h2>;
  };
  return (
    <div
      className={styles.heroSection}
      style={{ backgroundImage: `url(${imagePath})` }}
    >
      <div className={styles.textSection}>
        <h1 className={styles.heading}>{heading}</h1>
        {subHeading ? (
          <h2 className={styles.subHeading}>
            {subHeading.split('').map((char, index) =>
              char === char.toUpperCase() && char.match(/[A-Z]/i) ? (
                <span key={index} className={styles.red}>
                  {char}
                </span>
              ) : (
                char
              ),
            )}
          </h2>
        ) : (
          ''
        )}
        <p className={styles.description}>{description}</p>
      </div>
    </div>
  );
};

export default HeroSection;
