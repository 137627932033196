import * as React from 'react';
import FeatureSectionCenterNoimage from '../components/FeatureSectionCenterNoimage/FeatureSectionCenterNoimage';
import FeatureSectionGridFour from '../components/FeatureSectionGridFour/FeatureSectionGridFour';
import HeroSection from '../components/HeroSection/HeroSection';
import Layout from '../components/layout';
import Seo from '../components/seo';

const JetsIndexPage = ({ data, location }) => {
  return (
    <div>
      <Layout>
        <HeroSection
          imagePath={'/corporate/bill1.png'}
          heading={'中小企業のトライをサポート'}
          subHeading={'Japan Enterprise Try Support'}
        />
        <FeatureSectionCenterNoimage
          description={`
          JETS社労士・行政書士事務所は、中小企業の経営・人材・マーケティングのサポートを実現します。

          当事務所は社会保険労務士、行政書士、FP（ファイナンシャルプランナー）3つの資格を組み合わせて総合的なサービスを展開いたします。        

          成長ポテンシャルを有する中小企業が各種助成金・補助金等を活用して様々なトライを行っていくことを強力にサポートいたします！
          `}
        />
        <FeatureSectionGridFour
          title="JETS社労士事務所でできること"
          sectionId="section3"
          cords={[
            {
              id: 3,
              title: '助成金',
              description: `助成金とは、雇用関連の取組みに支給される返済不要の給付金となります（厚生労働省所管）。キャリアアップ助成金（１人当たり最大80万円）、業務改善助成金（１事業所当たり最大600万円）等、種類も多数あります。当事務所ではそれぞれの企業様に合った助成金を提案し、計画書の作成から支給決定まで一貫してサポートいたします。`,
            },
            {
              id: 5,
              title: '就業規則・各種規程',
              description: `就業規則は、事業主が独自に定めることができる“会社の基本的なルール”で、労務管理の根幹を成すものですが、あまり深く考えずに体裁だけ整えているケースがよく見受けられます。働き方改革が推進される中、就業規則の重要度は増す一方と言えます。
              当事務所では就業規則の意義からご説明し、各企業の実情に合った就業規則づくりのお手伝いをいたします。
              `,
            },
            {
              id: 4,
              title: '採用支援',
              description: `少子高齢化が進展する中、全ての業種で人手不足が深刻化しています。とりわけ中小企業の人材採用は年々厳しくなっており、“選ばれる会社”を目指した取組みが必須となってきています。
              中小企業においては採用関連経費を大きく計上することは難しいことから、ハローワーク求人の有効活用も含めた取組みが重要となります。
              当事務所では、求人票の診断、給与体系の見直し提案等を通じた採用支援を行っています。
              `,
            },
            {
              id: 4,
              title: '労務サポート',
              description: `働き方改革関連法が続々と施行される中、労務管理に頭を抱える経営者が多くなっています。
            また、ＷＥＢ上に労務に関する情報が氾濫する中、誤った労務管理を続けた結果、多額の未払い残業代を請求されるといったケースが見受けられます。
            加えて、政府は各種申請の電子化を推進しており、中小企業は人材が限られる中での対応を迫られています。
            当事務所では、まずは30分の無料相談（ＺＯＯＭ、電話）又は会社訪問を通じて、クライアント企業の実態把握から業務を進めていきます。            
             `,
            },
          ]}
        />
        <FeatureSectionGridFour
          title="JETS行政書士事務所でできること"
          sectionId="section3"
          cords={[
            {
              id: 1,
              title: '補助金',
              description: `補助金は、各省庁や地方自治体の政策目標（目指す姿）に沿って、様々な分野で公募されており、新規事業の展開などに極めて有用なツールです。
              ただ、自社の取組みにマッチする補助金を探し出すことが難しく、申請書類の作成等も煩雑であるため、途中で断念するケースも見受けられます。
              当事務所では事業ヒアリングを通じた補助金の診断・提案から申請サポートまで、一貫したサービスを提供しています。
              `,
            },
            {
              id: 1,
              title: '外国人ビザ申請代行',
              description: `外国人ビザ申請は種類が多く、手続きも煩雑なため、行政書士にスポットで依頼しているケースも多いかと思います。近年、労務管理が厳格化されていく中、外国人労働者も例外ではないことから、手続きに当たっても労務の知識が求められる機会が増えてきています。
            当事務所は社労士・行政書士事務所として、二つの資格を掛け合わせたサービスの提供を心掛けています。

            外国人ビザ申請取次代行行政書士＜(福) 行19第97号＞
            `,
            },
            {
              id: 1,
              title: '融資サポート',
              description: `中小企業の事業拡大において、公的融資は非常に大きな存在となります。しかしながら、“公的融資の効果的な申請”をサポートするところが少ないのが現状です。
            当事務所では、地方銀行出身のスタッフの知見・経験を活かしつつ、商工会議所や金融機関等とのネットワークも駆使しながら、事業計画書・収支計画書の策定などの公的融資サポートを行っています。
            コロナ融資の返済が始まっている中、公的融資の重要度はますます大きくなっています。            
              `,
            },
            {
              id: 1,
              title: '農地転用',
              description: `日本の農業における後継者不足は深刻度を増しており、同時に農業従事者の高齢化も顕著になってきています。
            しかしながら、多くの農地は市街化調整区域内にあり、その転用は簡単ではありません。
            当事務所では、農地転用を希望する農家様のご要望をお聞きした上で、どのような手法が可能か、ともに検討しながら業務に当たっていきます。            
              `,
            },
          ]}
        />
      </Layout>
    </div>
  );
};

export default JetsIndexPage;

export const Head = () => <Seo />;
