// extracted by mini-css-extract-plugin
export var content = "FeatureSection-module--content--827bb";
export var cord = "FeatureSection-module--cord--8abcb";
export var description = "FeatureSection-module--description--94a9a";
export var featureSection = "FeatureSection-module--featureSection--6aa3a";
export var featureSectionInner = "FeatureSection-module--featureSectionInner--e779c";
export var featureSectionPrimary = "FeatureSection-module--featureSectionPrimary--fd571";
export var featureSectionSecondary = "FeatureSection-module--featureSectionSecondary--7aa03";
export var grid = "FeatureSection-module--grid--7ad52";
export var head = "FeatureSection-module--head--a871a";
export var headDescription = "FeatureSection-module--headDescription--2c97a";
export var headSubtext = "FeatureSection-module--headSubtext--c4f7b";
export var headText = "FeatureSection-module--headText--3a319";
export var image = "FeatureSection-module--image--1fbab";
export var title = "FeatureSection-module--title--15c0e";